<template>
    <div class="z-30">
        <SimulatorBase
            class="phone-wrapper absolute bottom-8 left-8 opacity-0 pointer-events-none"
            :class="{
                'opacity-100 pointer-events-auto': simulatorVisible,
            }"
            :screen="screen"
        />
        <button
            @click="handleSimulatorBubbleClick"
            class="h-10 w-10 rounded-full shadow-md transition duration-200 bg-orange-500 hover:bg-orange-600 cursor-pointer flex items-center justify-center ease-in-out relative text-white">
            <span
                v-if="hasNtoification && !simulatorVisible"
                class="h-3 w-3 rounded-full bg-red-700 absolute -top-2 -right-2">
            </span>
            <phoneIcon
                v-if="!simulatorVisible"
                class="h-5 w-5 fill-current" />
            <closeIcon
                v-if="simulatorVisible"
                class="h-5 w-5 fill-current" />
        </button>
    </div>
</template>

<script>
import {
    mapState,
} from 'vuex';

import phoneIcon from '@/assets/img/phone.svg';
import closeIcon from '@/assets/img/close.svg';

import SimulatorBase from './SimulatorBase.vue';

export default {
    name: 'Simulator',

    data() {
        return {
            simulatorVisible: false,
            hasNtoification: false,
            bootTime: 1500,
            screen: 'SplashScreen',
        };
    },

    computed: {
        ...mapState('simulator', [
            'connected',
        ]),
    },

    watch: {
        connected(newValue) {
            if (newValue === true) {
                this.screen = 'MainScreen';
            }
        },
    },

    methods: {
        handleSimulatorBubbleClick() {
            this.simulatorVisible = !this.simulatorVisible;

            if (!this.connected && this.screen === 'SplashScreen') {
                setTimeout(() => { this.screen = 'LaunchScreen'; }, this.bootTime);
            }
        },
    },

    components: {
        phoneIcon,
        closeIcon,
        SimulatorBase,
    },
};
</script>

<style lang="postcss">
.phone-wrapper {
    width: 19rem;
    height: 40rem;
}
</style>
